@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Indie+Flower&family=Nunito:ital,wght@0,200;0,800;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap");
body {
  background-color: #1e2333;
  z-index: 3;
}
#divmain {
  height: 95vh;
  margin-top: 5px;
}
#name {
  font-size: 64px;
}
#part {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
#desc {
  font-family: "Nanum Gothic", sans-serif;
}
#namelight {
  color: red;
}
.btn {
  border-radius: 5px;
}
#buttonref button {
  margin: 3% 1.5%;
}
#imgme {
  border-radius: 25%;
  margin-left: 18%;
  animation-name: pic;
  margin-top: 22vh;
  background: #1e2333;
  box-shadow: 3px 3px 9px #0c0e14, -3px -3px 9px #303852;
  width: 400px;
  height: 400px;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#divmain {
  display: flex;
  flex-direction: row;
}
#det {
  align-items: left;
  width: 30%;
  margin-top: 22vh;

  margin-left: 10%;
  color: white;
}
#we {
  justify-content: space-around;
  margin-left: 20px;
  background: #1e2333;
  box-shadow: 2px 2px 4px #0c0e14, -2px -2px 4px #303852;
}

.resume {
  margin: 5% 0;
}

#name {
  font-family: "Nanum Gothic", sans-serif;
  color: white;
}

.sub {
  font-family: "Nunito", sans-serif;
  color: white;
}
@media screen and (max-width: 800px) {
  #divmain {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  #imgme {
    border-radius: 25%;
    margin: 0 auto;
    width: 230px;
    height: 230px;
    margin-top: 5vh;
  }
  #det {
    margin-top: 6vh;
    margin-left: auto;
    margin-right: auto;
    width: 97%;
  }
  #det h5 {
    font-size: 18px;
  }
  #name {
    font-size: 35px;
  }
}
@keyframes pic {
  0% {
    box-shadow: 1px 1px 2px #0c0e14, -1px -1px 2px #303852;
  }
  15% {
    box-shadow: 2px 2px 4px #0c0e14, -2px -2px 4px #303852;
  }
  25% {
    box-shadow: 3px 3px 6px #0c0e14, -3px -3px 6px #303852;
  }
  35% {
    box-shadow: 4px 4px 8px #0c0e14, -4px -4px 8px #303852;
  }
  50% {
    box-shadow: 5px 5px 10px #0c0e14, -5px -5px 10px #303852;
  }
  65% {
    box-shadow: 4px 4px 8px #0c0e14, -4px -4px 8px #303852;
  }
  75% {
    box-shadow: 3px 3px 6px #0c0e14, -3px -3px 6px #303852;
  }
  85% {
    box-shadow: 2px 2px 4px #0c0e14, -2px -2px 4px #303852;
  }
  99% {
    box-shadow: 1px 1px 2px #0c0e14, -1px -1px 2px #303852;
  }
}
