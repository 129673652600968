@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Indie+Flower&family=Nunito:ital,wght@0,200;0,800;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap");

#main {
  margin: 5% auto;
}

#head {
  font-family: "Comfortaa", cursive;
  color: white;
  margin-bottom: 5%;
}
.maindiv {
  width: 80vw;
  height: fit-content;
  background-color: #3a415e;
  display: flex;
  flex-direction: column;
  margin: 3%;
  border-radius: 10px;
  background: #1e2333;
  box-shadow: 3px 3px 9px #0c0e14, -4px -3px 9px #303852;
}
.whole {
  width: 82vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.img-div {
  width: 40%;

  margin: 2%;
}
.content-div {
  margin: 2%;
  height: 90%;
  width: 40%;
  text-align: left;
  color: white;
  font-family: "Nanum Gothic", sans-serif;
}
span {
  background-color: ash;
  padding: 0;
  border-radius: 3px;
}
#tech {
  background-color: rgb(129, 120, 120);
  width: fit-content;
  border-radius: 5px;
  padding: 2px;
  color: white;
}
.bttn {
  margin-right: 1%;
}
.download {
  width: 100%;
  height: 40px;
  background-color: red;
}
@media all and (max-width: 700px) {
  .maindiv {
    flex-direction: column;
    width: 90%;
    border-radius: 10px;
    margin: 4% auto;
  }
  .img-div {
    margin: 2% auto;
    width: 90%;
  }
  .content-div {
    width: 90%;
    margin: 2% auto;
  }
}
